import { Colors } from "../../../../@types/colors";

interface Props {
  text?: Colors | "white" | "dark";
  color?: Colors | string;
  isClickable?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  [x: string]: any;
}

const Badge = ({
  text = "white",
  children,
  color = Colors.Default,
  isClickable,
  style,
  ...rest
}: Props) => {
  return (
    <b
      style={{
        color: text,
        ...(style ? style : {}),
      }}
    >
      <span
        {...rest}
        style={{
          fontSize: "12px",
          backgroundColor: color,
          border: "1px solid",
          borderColor: color,
          borderRadius: "4px",
          paddingLeft: "5px",
          paddingRight: "5px",
          ...(isClickable ? { cursor: "pointer" } : {}),
        }}
      >
        {children}
      </span>
    </b>
  );
};

export default Badge;
